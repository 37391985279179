/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import 'react-toastify/dist/ReactToastify.css';

export interface LayoutProps {
  children: React.ReactNode;
  disableCookieBanner?: boolean;
  theme?: 'dark-solid' | 'dark-transparent' | 'light';
}

type CookieCategory = 'technically_required' | 'analytics' | 'marketing';

const Layout = ({
  children,
  theme = 'light',
}: LayoutProps): React.ReactElement => {
  const [acceptedCategories, setAcceptedCategories] = useState<
    CookieCategory[]
  >([]);

  return (
    <>
      <Helmet bodyAttributes={{ class: theme }}>
        <link
          rel="preload"
          href="/api/v1/instructors"
          as="fetch"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/api/v1/video-sessions"
          as="fetch"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/api/v1/courses"
          as="fetch"
          crossOrigin="anonymous"
        />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
      </Helmet>
      {children}
      <ToastContainer />
    </>
  );
};

export default Layout;
